import { IFormDataa, IOrdersState, IOrderState, ModalType } from "./type";

import { atom } from "recoil";

export const OrdersState = atom<IOrdersState>({
  key: "orders-state",
  default: {
    openOrder: [],
    executeOrder: [],
  },
});

export const VisibleModifyState = atom<boolean>({
  key: "is-visible-modifying-state",
  default: false,
});

export const VisibleCancelState = atom<boolean>({
  key: "is-visible-cancel-state",
  default: false,
});

export const VerfyOrderState = atom<any>({
  key: "VerfyOrderState",
  default: null,
});

export const VerifyOrderModalType = atom<ModalType>({
  key: "VerifyOrderModalType",
  default: ModalType.DEFAULT,
});

export const SelectedOrderState = atom<IOrderState>({
  key: "selected-order-state",
  default: {
    id: "",
    pagingToken: "",
    category: "",
    orderdate: "",
    quantity: 0,
    price: 0,
    completed: 0,
    remaining: 0,
    totalamount: 0,
    type: "",
    symbol: "",
    current_loan_balance: "",
    government_guaranteed_loan_balance: "",
    polygonData: {
      transaction: {
        url: "",
        hash: "",
      },
      approval: {
        url: "",
        hash: "",
      },
    },
  },
});

export const CollapseState = atom<boolean>({
  key: "collapse-state",
  default: true,
});

export const CollapseSideBarState = atom<boolean>({
  key: "collapse-sidebar-state",
  default: false,
});

export const defaultFormDatastate = atom<IFormDataa>({
  key: "default-Form-Data-state",
  default: {
    symbol: "",
    type: "",
    price: "" || 0,
    limit: "",
    qty: "" || 0,
  },
});

export const defaultModifyDatastate = atom<IFormDataa>({
  key: "default-modify-Data-state",
  default: {
    symbol: "",
    type: "",
    price: "" || 0,
    limit: "",
    qty: "" || 0,
  },
});

export const goodTillCancelledState = atom<boolean>({
  key: "good-till-state",
  default: true,
});
