import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReactResponsiveTable } from "@storybook";
// @ts-ignore
import { Json } from "@types/common";
import { downloadGoogleapisFile } from "@utils/common";
import { Button } from "@components/button";
import { UPLOAD_TYPE } from "@views/auction/create-auction/components/auctionLoanForm/constant";
import { useCurrency, useNotification, useNetwork, useWehAuthn, usePrevious } from "../../../../../hooks";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FortressAccountInfo } from "views/MyWallet/store/state";
import { Dropdown } from "@components/Dropdown";
import { APIS, Messages } from "constant";
import { assetTabs, gov_guaranteed_loan, ORDERBOOK_SERIES, SBA7A } from "@views/exchange/constants";
import { DeviceBiometrics } from "@components/device-biometrics";
import { userPersonalDetails } from "@states/user";
import { Loader, ReactModal } from "components";
import { downloadExcel } from "@utils/table-utils";
import { SbaFileUpload } from "../sba-file-upload";
import BuysellConfirmation from "@views/exchange/components/Actions/Form/buysellConfimation/buysellConfirmation";
import { ActiveAssetTab, ActiveCurrencyState, SocketTriggeredOnAssetIdState } from "@states/exchange";
import { defaultFormDatastate, ModalType, VerifyOrderModalType, VisibleModifyState } from "@states/Orders";
import VerifyOrder from "@views/exchange/components/Actions/Form/VerifyOrder/VerifyOrder";
import { ALLOW_MPC_ENV as ALLOW_MPC, ALLOW_MPC_ENV } from "envs";
import { IssuanceSignDoc } from "@views/exchange/components";
import { primaryIssuanceState } from "@views/exchange/components/Orders/store/state";
import { KycReview } from "@views/exchange/components/compliance/kyc-review";
import { formatNumberWithCommas } from "@utils/format-number";

const {
  BUYCONFIRMATION,
} = Messages;

export const BidSheetLoan = ({ auctionDetails }: Json) => {
  const {  bondPointToAmount, amountToBondPoints, formatCurrency} = useCurrency();
  const inputAskingRefs: any = useRef([]);
  const inputAskingRefs2: any = useRef([]);
  const [askingpriceSelected, setAskingPriceSelected] = useState("Bond Points");
  const [selectedInputs, setSelectedInputs] = useState<any>([]);
  const availableBalance = useRecoilValue<any>(FortressAccountInfo);
  const [orderSeriesData, setOrderSeriesData] = useState<any>([]);
  const { get: getOrderSeriesData } = useNetwork({ updateState: false });
  const { post: postBulkOrder } = useNetwork();
  const { post: postIssuanceUrl, loading: issuanceLoading } = useNetwork();
  const { successNotification, errorNotification } = useNotification();
  const [isbiometricLoading, setIsbiometricLoading] = useState(false);
  const [initiateBiometric, setInitiateBiometrics] = useState(false);
  const { getWebAuthnSupported } = useWehAuthn();
  const personalData = useRecoilValue(userPersonalDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitApiCall, setIsSubmitApiCall] = useState(false);
  const [sbaFileUploadModal, setSbaFileUploadModal] = useState(false);
  const [openVerifyOrder, setOpenVerifyOrder] = useState(null);
  const [modalType, setModalType] = useRecoilState<ModalType>(VerifyOrderModalType);
  const [visibleCanfirmation, setVisibleCanfirmation] = useState(false);
  const socketTriggeredOnAssetIdValue = useRecoilValue(SocketTriggeredOnAssetIdState);
  const prevSocketTrigger = usePrevious(socketTriggeredOnAssetIdValue);
  const formDataInitial = useRecoilValue(defaultFormDatastate);
  const [formData, setFormData] = useState(formDataInitial);
  const activeAssetTab = useRecoilValue(ActiveAssetTab);
  const isManuHomeLoan = activeAssetTab.key === assetTabs.MHL
   const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const [primaryIssuance, setPrimaryIssuance] =
    useRecoilState(primaryIssuanceState);
  const setVisibleModify = useSetRecoilState(VisibleModifyState);
  const [showKYCReview,setShowKYCReview]=useState(false);


  const countRef = useRef(null);

  const bidSheetSet: any = new Set([
    "bid_price_bond_points",
    "bid_price_USD",
    "highest_bid_bond_points",
  ]);
 
  const isOrderBook = auctionDetails?.sbaType === ORDERBOOK_SERIES;
  const isGovGuarunteed = auctionDetails?.loanType === gov_guaranteed_loan;
 
  const walletBalance = useMemo(() => {
    return `${formatCurrency(availableBalance?.balance ?? "0.00", 2)} `;
  }, [formatCurrency, availableBalance?.balance]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        inputAskingRefs.current.every(
          (ref: any) => ref && !ref.contains(e.target)
        ) &&
        inputAskingRefs2.current.every(
          (ref: any) => ref && !ref.contains(e.target)
        )
      ) {
        setSelectedInputs([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedInputs]);

  const offsetValue = useMemo(() => {
    return orderSeriesData?.length === 0
      ? 0
      : Math.ceil(orderSeriesData?.length / 20) - 1;
  }, [orderSeriesData?.length, isSubmitApiCall]);

  const initialQuery: any = useMemo(() => {
    return {
      limit: 20,
      offset: offsetValue,
    };
  }, []);

  const [filters, setFilters] = useState(initialQuery);

  useEffect(() => {
    setFilters(initialQuery);
  }, [initialQuery, auctionDetails]);

  useEffect(() => {
    if (socketTriggeredOnAssetIdValue?.type === "BIDSHEET" && prevSocketTrigger?.timeStamp !== socketTriggeredOnAssetIdValue?.timeStamp) {
      const existIdx = orderSeriesData?.findIndex((data: Json) => data?.orderDetails?.assetId === socketTriggeredOnAssetIdValue?.metadata?.assetId);
      const isUserSame = personalData?.id === socketTriggeredOnAssetIdValue?.metadata?.userId;
      if (existIdx > -1) {
        orderSeriesData[existIdx] = {
          ...orderSeriesData[existIdx],
          rowData: {
            ...orderSeriesData[existIdx]?.rowData,
            bid_price_USD: "",
            bid_price_bond_points: "",
          },
          orderDetails: {
            ...orderSeriesData[existIdx]?.orderDetails,
            ...socketTriggeredOnAssetIdValue?.metadata?.orderDetails,
            ...(!isUserSame ? {hasUserBid: false, isUserHighestOrderPrice: false} : {})
          }
        }
        setOrderSeriesData(addNewKeys([...orderSeriesData]));
      }
    }
  }, [socketTriggeredOnAssetIdValue, personalData?.id]);

  const handleFetchMore = useCallback(async () => {
    if(!isOrderBook) return {};
    let query: Json = {
      ...filters,
      offset: offsetValue + 1,
    };
    setFilters((prev:any) => ({ ...prev, offset: offsetValue + 1, }));
    fetchOrderSeriesData(query?.offset)
  }, [filters, offsetValue, auctionDetails]);

  const abortControllerRef = useRef<any>(null);

  const fetchOrderSeriesData = useCallback((offset: number)=>{
    const { signal } = abortControllerRef.current;
    getOrderSeriesData(
      `${APIS.SubAssetList}?parentId=${auctionDetails?.id}&limit=20&offset=${offset}`,
      { signal }
    )
    .then((resp) => {
      if (resp?.data) {
        setOrderSeriesData((prev:any) => addNewKeys([...prev, ...resp?.data?.subAssetArray]));
        countRef.current = resp?.data?.count;
      }
    });
  },[ getOrderSeriesData, auctionDetails, abortControllerRef.current]);

  useEffect(()=>{
    setOrderSeriesData([]);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    isOrderBook && fetchOrderSeriesData(offsetValue)
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  },[isOrderBook, auctionDetails]);

  function addNewKeys(data: any) {
    if(!data) return [];

    const bidsheet = data.map((item: Json, index: number) => {
      return addNewKeysOnsingleElement(item);
    });
    return bidsheet;
  }

  const addNewKeysOnsingleElement = (item: Json)=>{
      const {rowData, orderDetails} = item;
      const highestOrderPrice = item?.orderDetails?.highestOrderPrice;
      const isUserHighestOrderPrice = item?.orderDetails?.isUserHighestOrderPrice;
      const isTypeString = highestOrderPrice && typeof(highestOrderPrice) === 'string' && isNaN(Number(highestOrderPrice));
      const finalValue = isTypeString ? <label>{highestOrderPrice}</label> : <div><label className="greenValue">{amountToBondPoints(isGovGuarunteed ? rowData?.government_guaranteed_loan_balance : (rowData?.current_loan_balance || rowData?.CurrentPrincipalBalance) , highestOrderPrice ?? 0, 6)}(=${highestOrderPrice ? formatNumberWithCommas(Number(highestOrderPrice).toFixed(2)) : 'N/A'})</label>{isUserHighestOrderPrice && <label className="yellowValue">You</label>}</div>
      rowData["highest_bid_bond_points"]= ()=> finalValue;
      return {
        orderDetails,
        rowData
      };
  }

  const handleKeyDown = (e: any, index: any, type: string) => {
    const inputSelectType =
      type === "bp" ? "bid_price_bond_points" : "bid_price_USD";
    if (e.key === "Enter") {
      setSelectedInputs([]);
      e.preventDefault();

      if (type === "bp") {
        const nextIndex = (index + 1) % inputAskingRefs.current.length;
        inputAskingRefs.current[nextIndex].focus();//
      } else {
        const nextIndex = (index + 1) % inputAskingRefs2.current.length;
        inputAskingRefs2.current[nextIndex].focus();
      }
    }

    // clear the inputs when user deletes the value
    if (
      (e.key === "Delete" || e.key === "Backspace") &&
      selectedInputs.length > 0
    ) {
      handleClearSelected(inputSelectType);
    }
  };

  const handleClearSelected = (name: string) => {
    for (let i = 0; i < selectedInputs.length; i++) {
      updateBidPrice({ target: { name, value: "" } }, selectedInputs[i]);
    }
    setSelectedInputs([]);
  };

  const handleMouseDown = (e: any, index: any) => {
    if (e.ctrlKey || e.shiftKey) {
      selectUserSelection(index);
    } else {
      setSelectedInputs([]);
    }    
  };

  const selectUserSelection = (index: number) => {
    setSelectedInputs((prevSelected: any) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i: any) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const updateBidPrice = (e: any, index: any) => {
    const { value, name, pattern } = e?.target ?? {};
    const inputRegex = new RegExp(pattern)
    if (!inputRegex.test(value)) return;
    const updatedData =  [...orderSeriesData] ;
    updatedData[index]["rowData"][name] = value;
    const { current_loan_balance, CurrentPrincipalBalance, government_guaranteed_loan_balance } =
      updatedData[index]["rowData"];

    if (name === [...bidSheetSet][0]) {
      updatedData[index]["rowData"][[...bidSheetSet][1]] =
        value.length ? bondPointToAmount(isGovGuarunteed ? government_guaranteed_loan_balance : (current_loan_balance || CurrentPrincipalBalance), value).toFixed(2) : "";
    } else {
      updatedData[index]["rowData"][[...bidSheetSet][0]] =
        value.length
          ? amountToBondPoints(isGovGuarunteed ? government_guaranteed_loan_balance : (current_loan_balance || CurrentPrincipalBalance), value ?? 0, 6)
          : "";
    }
    setOrderSeriesData([...updatedData] );
  };

  const updateMultipleAskingPrice = (index: any) => (event: any) => {
    // Prevent the default paste action
    event.preventDefault();

    let { name } = event?.target;
    const pasteData = event?.clipboardData?.getData("Text");
    // Split the data by new lines
    const dataArray = pasteData?.split("\n");
    let updatedIndex = index;
    for (let i = 0; i < dataArray.length; i++) {
      updateBidPrice(
        { target: { name, value: dataArray[i] } },
        updatedIndex
      );
      updatedIndex++;
    }
  };

  const handleSubmitOrderSeries = useCallback(() => {
    let payload: any = [];
    setIsLoading(true);
    setInitiateBiometrics(false);
    orderSeriesData.map((item: any, index: number) => {
      if (
        +item?.rowData["bid_price_USD"] &&
        !item?.orderDetails?.sellOrderStatus
      ) {
        payload.push({
          assetId: item.orderDetails.assetId,
          price: +item?.rowData["bid_price_USD"],
          type: "BUY",
        });
        if (!ALLOW_MPC_ENV && activeCurrency?.isIssuanceEnabled) {
          payload = { ...payload, signDocId: primaryIssuance?.signDocId };
        }
      }
    });
    if (payload.length) {
      postBulkOrder(`${APIS.BulkOrder}?bulkOrder=true`, payload, {
        apiResponse: true,
      }).then((resp) => {
        setIsLoading(false);
        if (resp?.message !== "ok") {
          errorNotification(resp?.message);
        } else if (resp?.data?.data?.orderRequestId) {
          setOpenVerifyOrder(resp?.data?.data);
          setModalType(ModalType.CONFIRM);
        } else {
          setOrderSeriesData([]);
          setIsSubmitApiCall(!isSubmitApiCall);
          fetchOrderSeriesData(0);
          successNotification("order placed successfully.");
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [
    orderSeriesData,
    activeCurrency?.isIssuanceEnabled,
    primaryIssuance?.signDocId,
  ]);


  const handleSignDoc = useCallback(() => {
    const { price, qty: quantity } = formData ?? {};
    const total = Number(formData?.price) * Number(formData?.qty);
     const assetId = activeCurrency?.id;
    postIssuanceUrl(APIS.PRIMARY_ISSUANCE, {
      price,
      quantity,
      total,
      assetId,
    }).then((res) => {
       if (res?.docUrl) {
         setVisibleCanfirmation(false);
         setVisibleModify(false);
         setPrimaryIssuance({ ...res, signDocModal: true });
       } else {
         errorNotification(res?.message);
       }

    });
  }, [activeCurrency?.id, formData]);

  const handelOrderPlace = useCallback(() => {
      setIsbiometricLoading(true);
      if (!getWebAuthnSupported()) {
        handleSubmitOrderSeries();
      } else {
        setInitiateBiometrics(true);
      }
  }, []);

  
  const handleOrderAction = useCallback(async () => {
    if (availableBalance?.balance < 0) {
      errorNotification("Wallet balance is not sufficient");
    } else {
      if (personalData?.isTradeEnabled === false) {
        return errorNotification("Trading is not allowed");
      }

       if (!ALLOW_MPC && activeCurrency?.isIssuanceEnabled) {
         handleSignDoc();
       } else {
         handelOrderPlace();
       }

    }
  }, [availableBalance?.balance, personalData?.isTradeEnabled, initiateBiometric, isbiometricLoading, getWebAuthnSupported]);

  const handleFailure = useCallback(() => {
    setInitiateBiometrics(false);
  }, []);

  const disableButton = useMemo(()=>{
    return !orderSeriesData.some((item: any) => +item?.rowData["bid_price_USD"]);
  },[orderSeriesData])

  const getOrderBookSeriesData = async () => {
    const response = await getOrderSeriesData(
      `${APIS.SubAssetList}?parentId=${auctionDetails?.id}&limit=${countRef.current}&offset=0`)

    if(response?.data){
      return response?.data?.subAssetArray ?? {};
    }
    else{
      errorNotification('Something went wrong')
    }
  }

  function processOrderData(data: Json) {
    return data.map((item: Json) => {
      const { orderDetails, rowData } = item;
      const { highestOrderPrice } = orderDetails;
  
      const highestBondPointValue = amountToBondPoints(
        isGovGuarunteed
          ? rowData?.government_guaranteed_loan_balance
          : (rowData?.current_loan_balance || rowData?.CurrentPrincipalBalance),
          highestOrderPrice ?? 0,
        6)
      
      // Create a new rowData object
      const newRowData = { ...rowData };
  
      // Delete the bid_price_USD property
      const removeColumnName = askingpriceSelected === 'Bond Points' ? "bid_price_USD" : "bid_price_bond_points" ;
      delete newRowData[removeColumnName];
      const highestBidValue = highestOrderPrice === 'No bid placed' ? highestOrderPrice : `${highestBondPointValue} (=$${highestOrderPrice})`
      // Convert the newRowData object to an array of key-value pairs
      const entries = Object.entries(newRowData);
  
      // Insert highestOrderPrice at the third index
      entries.splice(1, 0, ["highest_bid_bond_points", highestBidValue]);
  
      // Convert the array back to an object
      const updatedRowData = Object.fromEntries(entries);
  
      return updatedRowData;
    });
  }
  
  const downLoadBidSheet = async () => {
    // get latest bidSheetData
   const data = await getOrderBookSeriesData();
   const processedData = processOrderData(data);
  
   if(processedData.length){
     await downloadExcel(processedData)
   }
  }

  const handleClose = useCallback(() => {
    setVisibleCanfirmation(false);
    setInitiateBiometrics(false);
  }, []);

  const handleSubmit =  ()=> {
    const totalBidPrice = orderSeriesData.reduce((acc: number, item: any) => {
    const bidPrice = parseFloat(item?.rowData?.["bid_price_USD"] || 0); // Extract and convert to a number, default to 0 if undefined
      return acc + bidPrice; // Accumulate the sum
    }, 0);
    if(personalData?.signDocStatus === "processing"){
      setShowKYCReview(true)
      return;
    }
    setFormData((prev) => ({ ...prev, price: totalBidPrice , qty:1 }));
      

    setVisibleCanfirmation(true)
    
  }

  const renderConfirmation = useMemo(() => {
    return (
      <BuysellConfirmation
        title={`Do you confirm to proceed with this order?`}
        formData={formData}
        visible={visibleCanfirmation}
        description={BUYCONFIRMATION}
        handleModal={handleOrderAction}
        handleClose={handleClose}
        isOrderModifying={false}
        label={"BUY"}
        type={"buy"}
        cancelLoading={isbiometricLoading || issuanceLoading}
      />
    );
  }, [formData, visibleCanfirmation, handleOrderAction, handleClose, isbiometricLoading, issuanceLoading]);



  const renderBidSheetTable = useMemo(() => {
    if (!orderSeriesData?.length && !auctionDetails?.bidSheetJSON) return []

    let newRow;
    let keys;
    let disableRows: any = [];
    if (isOrderBook) {
      newRow = orderSeriesData?.map(
        (item: any, index: number) => {
        
          const { bid_price_bond_points, bid_price_USD,highest_bid_bond_points, asking_price_USD, asking_price_bond_points, ...restItem } = item?.rowData || {};

          const tempItem = {asking_price_USD: formatNumberWithCommas(asking_price_USD), asking_price_bond_points: formatNumberWithCommas(asking_price_bond_points), ...restItem}

          const hasUserBid = item?.orderDetails?.hasUserBid;
          const sellOrderStatus = item?.orderDetails?.sellOrderStatus;
          const userHighestOrderPrice = item?.orderDetails?.userHighestOrderPrice;
          const disableLabelValue = !sellOrderStatus ? amountToBondPoints(isGovGuarunteed ? item?.rowData?.government_guaranteed_loan_balance : (item?.rowData?.current_loan_balance || item?.rowData?.CurrentPrincipalBalance), userHighestOrderPrice ?? 0, 6) : <label className="soldValue">SOLD</label>;
          
          (sellOrderStatus || hasUserBid) && disableRows.push(index)

           return {
            bid_price_bond_points: () => (
              !(sellOrderStatus) && (!hasUserBid || bid_price_bond_points)?
                <input
                  type="text"
                  disabled={askingpriceSelected === "USD"}
                  ref={(el) => (inputAskingRefs.current[index] = el)}
                  name={`bid_price_bond_points`}
                  value={askingpriceSelected === "USD" ? formatNumberWithCommas(bid_price_bond_points) : bid_price_bond_points}
                  onChange={(event) => updateBidPrice(event, index)}
                  pattern="^(10000(\.\d{0,6})?|(\d{0,4})(\.\d{0,6})?)$"
                  onPaste={updateMultipleAskingPrice(index)}
                  onKeyDown={(e) => handleKeyDown(e, index, "bp")}
                  onMouseDown={(e) => handleMouseDown(e, index)}
                  style={{
                    border: selectedInputs.includes(index)
                      ? "1px solid #777E90"
                      : "",
                  }}
                />
                :
                <label>{disableLabelValue}</label>
              ),
            bid_price_USD: () =>
              (askingpriceSelected === "USD" && !(sellOrderStatus) && (!hasUserBid || bid_price_bond_points)) ? (
                <input
                  type="text"
                  disabled={askingpriceSelected !== "USD"}
                  ref={(el) => (inputAskingRefs2.current[index] = el)}
                  name={`bid_price_USD`}
                  value={bid_price_USD}
                  onChange={(event) => updateBidPrice(event, index)}
                  pattern="^(10000000000(\.\d{0,2})?|(\d{0,11})(\.\d{0,2})?)$"
                  onPaste={updateMultipleAskingPrice(index)}
                  onKeyDown={(e) => handleKeyDown(e, index, "usd")}
                  onMouseDown={(e) => handleMouseDown(e, index)}
                  style={{
                    border: selectedInputs.includes(index)
                      ? "1px solid #777E90"
                      : "",
                  }}
                />
              ) : (
                sellOrderStatus || (hasUserBid && !bid_price_bond_points) ?
                  !sellOrderStatus ?
                    <label>{'$' + formatNumberWithCommas(Number(userHighestOrderPrice))}</label>
                    :
                    <label></label>
                : 
                <label>
                    {bid_price_USD ? '$' +
                    formatNumberWithCommas(Number(bid_price_USD))
                    : null}
                </label>
              ),
              highest_bid_bond_points,
            ...tempItem,
          };
        }
      );

      keys = { ...newRow?.[0] };
    } else {
      keys = auctionDetails?.bidSheetJSON[0];
    }

    const rows =  isOrderBook
      ? newRow
      : auctionDetails?.bidSheetJSON;
    const column = Object.keys(keys || {})
    .filter(key => key !== 'marked')
    .map((key) => ({
      label: key,
      key: key,
      format: bidSheetSet.has(key) ? "jsx" : "string",
      width: "10%",
    }));
    return (
      <div className={`bid-sheet ${isOrderBook ? "bid-sheet-pool" : ""}`}>
        <div className={`bid-sheet_heading_container ${!isOrderBook ? "bid-sheet_heading_container-pool" : ""}`}>
          <div className={`${isOrderBook ? "bid-sheet_heading_text" : ""}`}>
            <h2 className="bid-sheet__heading">Bid Sheet</h2>
            {isOrderBook && <p className="bid-sheet__para">Review details & bid on your preferred loan(s)</p>}
          </div>

          {isOrderBook ? (
            <div className="bid-price-container">
              <p className="bid-price-container__heading2">Bid in</p>
              <Dropdown
                className="dropdown"
                classDropdownHead="dropdownHead"
                classDropdownArrow="dropdownArrow"
                classDropdownBody="dropdownBody"
                classDropdownOption="dropdownOption"
                value={askingpriceSelected as string}
                setValue={setAskingPriceSelected}
                options={["Bond Points", "USD"]}
              />
            </div>
          ) : (
            <Button
              type="btn-outline-blue"
              label="Download"
              handleClick={() => handleClickDownload(UPLOAD_TYPE?.BIDSHEET)}
            />
          )}

          {isOrderBook && (
            <div className="bid-price-btn-container">
              <Button
                type="btn-outline-blue btn-auto"
                label="Import Bid Sheet"
                handleClick={() => setSbaFileUploadModal(true)}
              />
              <Button
                type="btn-outline-blue"
                label="Download"
                handleClick={downLoadBidSheet}
              />
            </div>
          )}
        </div>

        <ReactResponsiveTable
            className={
              isOrderBook
                ? "table-cell1"
                : ""
            }
            scrollYDirectionLeft = {isOrderBook}
          rows={rows || []}
          column={column}
          disableColumn={
            isOrderBook ? [askingpriceSelected === "USD" ? 0 : 1] : []
          }
          disableRow={disableRows}
          handleFetchMore={handleFetchMore}
          offsetValue={offsetValue}
          filters={filters}
        />

        {isOrderBook && <div className="bid-sheet-footer">
            <div>
              <p className="footer-heading">Wallet balance</p>
              <p className="footer-para">{walletBalance}</p>
            </div>
          <Button type="button" label={isLoading ? <Loader  dimension={26} />
                 : "Submit order"}
              disabled={disableButton}
              handleClick={handleSubmit}
            />
        </div>}
      </div>
    );
  }, [ auctionDetails?.bidSheetJSON, isOrderBook, askingpriceSelected, walletBalance, orderSeriesData, selectedInputs, bidSheetSet]);

  const renderSBA7Table = useMemo(() => {
    if (!auctionDetails?.sba1502JSON?.length) return ""
    const column = Object.keys(auctionDetails?.sba1502JSON?.[0]).map((key) => ({
      label: key, key: key, format: "string", width: "10%",
    }))
    return (
      <div className="bid-sheet">
        <div className={`bid-sheet_heading_container ${!isOrderBook ? "bid-sheet_heading_container-pool" : ""}`}>
          <h2 className="bid-sheet__heading">{isManuHomeLoan ? "Loan Servicing File" : `${SBA7A} form 1502`}</h2>
          {!isOrderBook && <Button type="btn-outline-blue" label="Download"
              handleClick={() => handleClickDownload(UPLOAD_TYPE?.SBA1502)} />}
        </div>
        <ReactResponsiveTable
          className={
            isOrderBook
              ? "table-cell1"
              : ""
          }
          scrollYDirectionLeft = {isOrderBook}
          rows={auctionDetails?.sba1502JSON || []}
          column={column} />
      </div>
    );
  }, [auctionDetails]);

  const handleClickDownload = (key: string) => {
    const file = auctionDetails?.dataRoom?.filter((file: Json) => file?.uploadType === key)?.[0] || {};
    downloadGoogleapisFile(file?.fileUrl, file?.fileName);
  }

  const setModalVisible = useCallback(
    (loading: boolean, isModalOpen: boolean) => {
      setVisibleCanfirmation(false)
      setIsbiometricLoading(loading);
      setInitiateBiometrics(isModalOpen);
    },
    []
  );

  const filterSymbolAndBidPrice = (arr: any, orders: any) => {
   const selectedColumnname = askingpriceSelected === 'Bond Points' ? 'bid_price_bond_points' : 'bid_price_USD'
   const notSelectedColumnname = askingpriceSelected !== 'Bond Points' ? 'bid_price_bond_points' : 'bid_price_USD'
  
   return arr.map((item: any, index: number) => ({
     Symbol: item.Symbol,
     [`${selectedColumnname}`]: item[selectedColumnname] || '',
     [`${notSelectedColumnname}`]:
       selectedColumnname === "bid_price_bond_points"
         ? bondPointToAmount(
             isGovGuarunteed
               ? orders[index]?.rowData?.government_guaranteed_loan_balance
               : (orders[index]?.rowData?.current_loan_balance || orders[index]?.rowData?.CurrentPrincipalBalance),
             item[selectedColumnname]
           ) || ''
         : amountToBondPoints(
             isGovGuarunteed
               ? orders[index]?.rowData?.government_guaranteed_loan_balance
               : (orders[index]?.rowData?.current_loan_balance || orders[index]?.rowData?.CurrentPrincipalBalance),
               item[selectedColumnname],
             6
           ) || '',
   }));
  };

  const checkValidFile = (json: any) => {
    let infileValid = true;
    const columnName = askingpriceSelected === 'Bond Points' ? 'bid_price_bond_points' : 'bid_price_USD'
  
    for (const item of json) {
      if (columnName in item) {
       if (
          item[columnName] !== '' &&
          (typeof item[columnName] !== "number" ||
          isNaN(item[columnName]))
        ) {
          infileValid = false;
          break;
        }
      }
    }

    return infileValid;
  }

  const preciseValue = (json: any) => {
    const precisionValue = askingpriceSelected === 'Bond Points' ? 6 : 2;
    const correctedJson = json.map((row: any) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [
          key,
          typeof value === 'number' ? parseFloat(value.toFixed(precisionValue)) : value,
        ])
      )
    );

    return correctedJson;
  }
 
  const handleFileUploadSuccess = async (json: any) => {
    const isFileValid = checkValidFile(json)
    const preciseJson = preciseValue(json);
    let isUploadSuccess = true;
    if(isFileValid){
      if(sbaFileUploadModal){
        const orders = await getOrderBookSeriesData();
        const filteredBisheetArray = filterSymbolAndBidPrice(preciseJson, orders);
      
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          const matchingData = filteredBisheetArray.find(
            (item: any) => item.Symbol === order.rowData.Symbol
          );
          if (matchingData) {
            order.rowData = { ...order.rowData, ...matchingData };
          } else {
            errorNotification(`Please upload a valid file.`);
            isUploadSuccess = false;
            break;
          }
        }
        const sortBySymbol = (data: any) =>{
          data.sort((a: { rowData: { symbol?: string } }, b: { rowData: { symbol?: string } }): number => {
            // Ensure both symbols are defined before attempting to match
            const symbolA = a?.rowData?.symbol ?? '';
            const symbolB = b?.rowData?.symbol ?? '';
        
            // Extract the number from the end of the symbol using a regular expression
            const numA = parseInt(symbolA.match(/\d+$/)?.[0] || '0', 10);
            const numB = parseInt(symbolB.match(/\d+$/)?.[0] || '0', 10);
        
            return numA - numB;
          });
          return data;
        };
        const sortedOrders = sortBySymbol(orders);
        const columnName = askingpriceSelected === 'Bond Points' ? 'bid_price_bond_points' : 'bid_price_USD';
        if (isUploadSuccess) {
          if (!json.some((item: any) => item.hasOwnProperty(columnName))){
            errorNotification("Upload the sheet with the correct columns.");
            return;
          }
          else{
            successNotification(`File uploaded successfully`);
          }
          setOrderSeriesData([]);
          setOrderSeriesData((prev: any) => addNewKeys([...prev, ...sortedOrders]));
          setSbaFileUploadModal(false);
        }
      }
      else{
        errorNotification('Something went wrong')
      }
    }
    else{
      errorNotification(`Please upload a valid file.`)
    }
  }

  return (
    <>
    <ReactModal
        visible={showKYCReview}
        onClose={()=>setShowKYCReview(false)}
        closeBtn={true}
        outerClassName={"KYCReviewModal"}
      >
       <KycReview/>
      </ReactModal>
      {isOrderBook && (
        <div className="bid-note">
          <i className="ri-alarm-warning-fill icon" />
          <div className="outer-para">
            Note:{" "}
            <p className="inner-para">
              Bid on individual loans are executed immediately if your price
              meets or exceeds the asking price. For bids below the asking
              price, the seller will be notified for review and order execution.
            </p>
          </div>
        </div>
      )}
      {renderBidSheetTable}
      {renderSBA7Table}
      {sbaFileUploadModal && (
        <SbaFileUpload
          onClose={(value: boolean) => setSbaFileUploadModal(value)}
          handleSuccess={(json: any) => handleFileUploadSuccess(json)}
        />
      )}
      <ReactModal
        visible={visibleCanfirmation}
        onClose={() => setVisibleCanfirmation(false)}
        maskClosable={false}
      >
        {renderConfirmation}
      </ReactModal>
      {initiateBiometric && (
        <DeviceBiometrics
          onSuccess={handleSubmitOrderSeries}
          onFailure={handleFailure}
          render={(isWebAuthLoading: boolean, isModalOpen: boolean) =>
            setModalVisible(isWebAuthLoading, isModalOpen)
          }
        />
      )}

      {!!openVerifyOrder && (
        <VerifyOrder
          isOpen={!!openVerifyOrder}
          onClose={() => setOpenVerifyOrder(null)}
          closeBtn={true}
          orderResponse={openVerifyOrder}
          modalType={modalType}
          setModalType={setModalType}
        />
      )}
      {primaryIssuance?.signDocModal && (
        <IssuanceSignDoc onSuccess={handelOrderPlace} />
      )}
    </>
  );
};
